// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()
require("channels")
require('datatables.net-bs4')

import $ from 'jquery';
global.$ = jQuery;
require("easy-autocomplete")

//window.Rails = Rails

import 'bootstrap'
import 'data-confirm-modal'
import 'packs/clients'
import "@fortawesome/fontawesome-free/css/all"

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})

$(document).on('turbolinks:load', function(){
  $("table[role='datatable']").each(function(){
    var page_array = window.location.href.split("/");
    var page = page_array[page_array.length - 1];
    if (page === 'orders') {
      var search_placeholder = 'Busca folio';
    } else {
      var search_placeholder = 'Busca nombre';
    }

    $(this).DataTable({
      processing: true,
      serverSide: true,
      ajax: $(this).data('url'),
      language: {
        url: '/js/datatables/language/spanish.json',
        scrollX: false,
        searchPlaceholder: search_placeholder
      }
    });
  });  
})

